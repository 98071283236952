<template>
  <div>
    <div v-if="id">
      <CreateEditAccount v-if="!isLoading" :is-loading="isLoadingAction" :id="id" :formData="formData" :name="name"
                         :errorMessages="errorMessages"
                         :errorChangePasswordMessage="errorChangePasswordMessage"
                         @onCreateAndUpdateAccount="onCreateAndUpdateAccount"
                         @onChangePassword="onChangePassword" @onChangeImageAvatar="onChangeImageAvatar"
                         @onChangeImageCover="onChangeImageCover" :onReset="(field) => onReset(field)"/>
    </div>
    <div v-else>
      <CreateEditAccount :formData="formData" :is-loading="isLoadingAction" :errorMessages="errorMessages"
                         @onCreateAndUpdateAccount="onCreateAndUpdateAccount" @onChangeImageAvatar="onChangeImageAvatar"
                         @onChangeImageCover="onChangeImageCover" :onReset="(field) => onReset(field)"/>
    </div>
  </div>
</template>

<script>
import CreateEditAccount from "@/components/facility-account/CreateEditAccount";
import {userService} from "@/services";
import {checkDuplicate, checkValidateInput} from "@/utils/facility-account";
import Swal from "sweetalert2";
import {ROLES, USER_ACTION, USER_STATUS} from "@/constants";
import {clientOSS, generateImageOss} from '@/helpers/axios/formDataRequest';

export default {
  name: "Create Account",
  components: {CreateEditAccount},
  data() {
    return {
      id: this.$route.params.id || "",
      isLoading: false,
      errorMessages: {
        firstName: {
          message: "",
          params: {}
        },
        lastName: {
          message: "",
          params: {}
        },
        phoneNumber: {
          message: "",
          params: {}
        },
        email: {
          message: "",
          params: {}
        },
        facilityId: {
          message: "",
          params: {}
        }
      },
      formData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        status: true,
        facilityId: '',
        role: ROLES.FACILITY_MANAGER
      },
      formChangePassword: {
        newPassword: "",
        confirmPassword: "",
      },
      errorChangePasswordMessage: {
        newPassword: {
          message: "",
          params: {}
        },
        confirmPassword: {
          message: "",
          params: {}
        },
      },
      name: "",
      fileAvatarImage: null,
      fileCoverImage: null,
      isLoadingAction: false
    }
  },
  mounted() {
    if (this.id) {
      this.getUserDetail(this.id);
    }
  },
  methods: {
    onReset(field) {
      this.errorMessages[field] = {
        message: "",
        params: {}
      }
    },
    onChangeImageAvatar(file) {
      this.fileAvatarImage = file;
    },
    onChangeImageCover(file) {
      this.fileCoverImage = file;
    },
    getImageURL(fileName) {
      return generateImageOss(fileName)
    },
    async getUserDetail(id) {
      try {
        this.isLoading = true;
        const res = await userService.getUser(id);
        if (res) {
          this.formData = {
            firstName: res.firstName,
            lastName: res.lastName,
            phone: res.phone,
            email: res.email,
            status: res.status === USER_STATUS.ACTIVE,
            pathAvatar: res?.pathAvatar,
            pathCover: res?.pathCover,
            facilityId: res?.facilityId,
            role: res?.role,
          };
          this.name = res.name;
          this.isLoading = false;
        }
      } catch (err) {
        console.log(err)
        this.isLoading = false;
      }
    },
    async upload(type) {
      let file = type === 'avatar' ? this.fileAvatarImage : this.fileCoverImage;

      if (file) {
        try {
          await clientOSS.put(`profile/${file.name}`, file)
          return `profile/${file.name}`
        } catch (error) {
          if (type === 'avatar') {
            return this.formData.pathAvatar;
          }
          return this.formData.pathCover;
        }
      }
    },
    async onCreateAndUpdateAccount(value) {
      this.formData = value;
      this.errorMessages = await checkValidateInput(this, this.formData, USER_ACTION.CREATE_UPDATE_ACCOUNT);
      let error = 0;
      this.isLoadingAction = true;
      for (const key in this.errorMessages) {
        if (this.errorMessages[key].message !== "") error += 1;
      }
      if (this.fileAvatarImage) {
        this.formData.pathAvatar = await this.upload('avatar');
      }
      if (this.fileCoverImage) {
        this.formData.pathCover = await this.upload('cover');
      }
      if (error === 0) {
        const username = (Math.random() + 1).toString(36).substring(7);
        const status = this.formData.status ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE;
        const params = {
          ...this.formData,
          firstName: this.formData.firstName.trim(),
          lastName: this.formData.lastName.trim(),
          username,
          status,
          role: this.formData.role || ROLES.FACILITY_MANAGER,
        };
        try {
          let res;
          if (this.id) {
            res = await userService.updateUser(this.id, params);
          } else res = await userService.createUser(params);
          if (res) {
            const message = this.id
              ? this.$t('msg.saved')
              : `<div class="d-flex flex-column flex-start">
                    <p class="fs-16 text-center">${this.$t('t-create-update-facility-account.success-message.t-create-success-first-line')}</p>
                    <div class="mt-4 d-flex flex-column flex-start">
                      <p class="fs-14 p-0 text-start">
                        <span class="box-120">${this.$t('t-create-update-facility-account.success-message.t-create-success-email')}</span> ${res.email}
                      </p>
                      <p class="fs-14 p-0 text-start">
                        <span class="box-120">${this.$t('t-create-update-facility-account.success-message.t-create-success-password')} </span>
                        ${res.password}
                      </p>
                    </div>
                  </div>`
            await Swal.fire({
              title: "",
              html: message,
              icon: "success",
              showConfirmButton: this.id ? false : true,
              timer: this.id ? 1500 : undefined
            })
            if (!this.id) {
              this.$router.push('/admin/setting/facility-account');
            }
            if (this.id) {
              this.formData = {
                firstName: res.firstName,
                lastName: res.lastName,
                phone: res.phone,
                email: res.email,
                status: res.status === USER_STATUS.ACTIVE,
                pathAvatar: res?.pathAvatar,
                pathCover: res?.pathCover,
                facilityId: res?.facilityId,
                role: res?.role,
              };
              this.name = res.name;
            }
          } else if (res.statusCode === 409 || res.statusCode === 400) {
            this.errorMessages = await checkDuplicate(this, res.message);
          }
        } catch (err) {
          if (err.status === 500) {
            Swal.fire("Error!", "There are some errors. Please try again.", "error");
          } else {
            this.errorMessages = await checkDuplicate(this, err.response?.data?.message);
          }

        }
      }

      this.isLoadingAction = false;
    },

    async onChangePassword(data) {
      const defaultFormData = data.formUpdateUser;
      this.formChangePassword = {...data.formChangePass};
      this.errorChangePasswordMessage = await checkValidateInput(this, this.formChangePassword, USER_ACTION.CHANGE_PASSWORD);

      let error = 0;
      for (const key in this.errorChangePasswordMessage) {
        if (this.errorChangePasswordMessage[key].message !== "") error += 1;
      }

      if (this.fileAvatarImage) {
        defaultFormData.pathAvatar = await this.upload("avatar");
      }
      if (this.fileCoverImage) {
        defaultFormData.pathCover = await this.upload("cover");
      }

      if (error === 0) {
        try {
          const paramsChangePass = {
            new: this.formChangePassword.newPassword
          };

          const resChangePassword = await userService.changeUserPassword(this.id, paramsChangePass);

          const status = this.formData.status ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE;

          let paramsUpdateImage = {
            ...defaultFormData,
            status
          };

          const resUpdateImage = await userService.updateUser(this.id, paramsUpdateImage);
          if (resChangePassword
            && resChangePassword.updated
            && resUpdateImage
            && resUpdateImage.id
          ) {
            await Swal.fire({
              title: "", text: this.$t('msg.saved'), icon: "success", showConfirmButton: false, timer: 1500
            })
            this.$router.push('/admin/setting/facility-account');
          }

        } catch (err) {
          console.log(err);
          Swal.fire("", this.$t("t-network-error"), "error");
        }
      }
    }
  }
}
</script>
